<template>
  <article>
    <section class="info-order__container">
      <p>{{ $t("orderType") }}: {{ $t(getOrderType) }}</p>
      <p v-if="orderTypeIsToHome">{{ $t("address") }}: {{ getAddress }}</p>
      <p>{{ $t("productsQty") }}: {{ getProductQty }}</p>
      <p>
        {{ $t("amountCart") }}:
        <span>{{ getTotalPayCart }}</span>
      </p>
      <p v-if="allowTips">
        {{ $t("amountTip") }}:
        <span>+{{ getAmountTip }}</span>
      </p>
      <p class="font-weight-bold">
        {{ $t("totalToPay") }}:
        <span>{{ getTotalToPay }}</span>
      </p>
    </section>

    <section
      v-if="orderType === 'AT_HOME' || orderType === 'TO_PICK_UP'"
      class="info-order__container mt-5 pb-0"
      style="padding-bottom: -10px"
    >
      <v-col class="d-flex flex-wrap pb-0 px-0 align-center">
        <label style="max-width: 150px" class="">
          {{ `${$t("deliveryTime")}:` }}
        </label>
        &nbsp;&nbsp;
        <v-select
          style="min-width: 200px; width: 60%"
          class="pb-0"
          solo
          v-model="scheduleOrderTime"
          :items="shiftsTimes"
          item-text="key"
          item-value="value"
          @change="emitScheduleOrderTime"
        >
        </v-select>
      </v-col>
    </section>

    <SelectTip
      v-if="allowTips"
      :totalToPay="getTotalToPayNumber"
      @noTipSelected="emitFinishOrderEvent"
      class="mt-5"
    />

    <section v-if="loading" class="flex loading">
      <div class="container">
        <h2 class="loading-text">
          {{ $t("loading") }}
        </h2>
        <base-loading class="spinner" />
      </div>
    </section>
  </article>
</template>
<script>
import {
  orderTypeName,
  orderTypeStoreGetters,
} from "@/store/modules/orderType/constNames";
import { settingsDigitalLetterStoreGetters } from "@/store/modules/settingsDigitalLetter/constNames";
import { formatPrice } from "@/helpers";
import { getErrorPayloadForSnackbar } from "@/helpers";
import { shoppingCartCtrl } from "@/controllers";

export default {
  name: "InfoOrder",
  components: {
    SelectTip: () => import("./SelectTip.vue"),
  },
  data: () => ({
    scheduleOrderTime: "",
    shiftsTimes: [],
    orderType: "",
    products: [],
    loading: false,
  }),
  mounted() {
    this.fetchTurns();
  },
  props: {
    priceDomicileService: { type: Number },
    visibleDialog: { type: Boolean, default: false },
  },
  watch: {
    visibleDialog(newValue) {
      if (newValue) this.fetchTurns();
    },
  },
  methods: {
    formatPrice,
    emitFinishOrderEvent() {
      this.$emit("finishOrderEvent");
    },
    async fetchTurns() {
      this.orderType = this.getOrderType;
      if (this.orderType != "LOCAL") {
        this.loading = true;
        this.products = this.getProducts;
        let prods = [];
        this.products.forEach((prod) => {
          prods.push({ id: prod.id, qty: prod.qty });
          if (prod.items && prod.items.length > 0) {
            prod.items.forEach((item) => {
              prods.push({ id: item.id, qty: item.qty });
            });
          }
        });
        let params = { products: prods };

        if (this.orderType === "TO_PICK_UP") params.type = "recoger";
        else if (this.orderType === "AT_HOME") params.type = "domicilio";

        try {
          const { data } = await shoppingCartCtrl.getShiftsTimes(params);
          if (data.shifts_times.length > 0) {
            this.shiftsTimes = data.shifts_times;
            this.shiftsTimes[0].key = this.$t("sooner");
            this.scheduleOrderTime = this.shiftsTimes[0].value;
            this.emitScheduleOrderTime();
          }
        } catch (e) {
          const errorPayload = getErrorPayloadForSnackbar(e);
          this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
          this.shiftsTimes = [{ key: this.$t("noAvailability"), value: "" }];
          this.scheduleOrderTime = this.shiftsTimes[0].value;
        }
        this.loading = false;
      }
    },
    emitScheduleOrderTime() {
      let sooner = false;
      if (this.scheduleOrderTime === this.shiftsTimes[0].value) {
        sooner = true;
      }
      this.$emit("updateOrderTime", this.scheduleOrderTime, sooner);
    },
  },
  computed: {
    getOrderToSend() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TO_SEND];
    },
    getProductQty() {
      const { products } = this.getOrderToSend;
      return products.length > 1
        ? products.reduce((qty, product) => qty + product.qty, 0)
        : products[0].qty;
    },
    getOrderType() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
    },
    getAddress() {
      return this.$store.getters[orderTypeStoreGetters.GET_ADDRESS].street;
    },
    getTotalPayCart() {
      const amountCart = this.getTotalToPayNumber;
      return this.formatPrice(amountCart);
    },
    getTotalToPay() {
      const amountCart = this.getTotalToPayNumber;
      const amountTip = this.$store.getters["cart/getTipInfo"].amount;

      return this.formatPrice(
        this.allowTips ? amountCart + amountTip : amountCart
      );
    },
    getAmountTip() {
      const amountTip = this.$store.getters["cart/getTipInfo"].amount;

      return this.formatPrice(amountTip);
    },
    getTotalToPayNumber() {
      return this.orderTypeIsToHome
        ? +this.$store.getters["cart/totalPrice"] + this.priceDomicileService
        : +this.$store.getters["cart/totalPrice"];
    },
    orderTypeIsToHome() {
      return this.getOrderType === orderTypeName.AT_HOME;
    },
    allowTips() {
      const typeIsToLocal = this.getOrderType === orderTypeName.LOCAL;
      const allowTips =
        this.$store.getters[settingsDigitalLetterStoreGetters.GET_ALLOW_TIPS];
      return !typeIsToLocal && allowTips;
    },
    getProducts() {
      return this.$store.getters["cart/getProducts"];
    },
  },
};
</script>

<style lang="scss" scoped>
.info-order__container {
  background: #fff;
  border-radius: 20px;
  padding: 10px 20px;

  p {
    display: flex;
    justify-content: space-between;
  }

  label {
    max-width: 150px;
    margin-bottom: 0px;

    @media (min-width: 420px) {
      margin-bottom: 25px;
    }
  }
}

.loading {
  background: var(--bgColor);
  opacity: 0.4;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $heading-font-family;

  &-text {
    color: rgb(0, 0, 0);
    text-align: center;
    text-transform: uppercase;
  }
  .spinner {
    margin: 20px auto;
    justify-content: center;
    max-height: max-content;
  }
}
</style>
